$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// set if you're also including font-awesome
// $fa-font-path: "~font-awesome/fonts";

//@import '~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';
//@import "~font-awesome/css/font-awesome.min.css";
@import '~bootstrap-sass/assets/stylesheets/bootstrap';

@import '~ui-select/dist/select';
@import '~toastr/toastr.scss';
/*
@import '~angular-xeditable/dist/css/xeditable.min.css';
@import '~select2/src/scss/core.scss';
@import '~select2-bootstrap-theme/src/select2-bootstrap.scss';*/

/*@import '~angular-ui-tree/dist/angular-ui-tree.min.css';
@import "tggleswitch";
@import '/partial/upload-button';*/

.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 3px;
  }
  & + .bs-callout {
    margin-top: -5px;
  }
}
@each $name,$color in (default,#777),(primary,#428bca),(success,#5cb85c),(danger,#d9534f),(warning,#f0ad4e),(info,#5bc0de), (bdc,#29527a) {
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}